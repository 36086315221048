import { Component, EventEmitter, inject, Input, Output } from '@angular/core'
import { CommonModule, JsonPipe } from '@angular/common'
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm, ReactiveFormsModule } from '@angular/forms'
import { NgSelectModule } from '@ng-select/ng-select'

@Component({
    selector: 'app-autocomplete-select',
    standalone: true,
    imports: [CommonModule, NgSelectModule, ReactiveFormsModule, JsonPipe],
    templateUrl: './autocomplete-select.component.html',
    styleUrls: ['./autocomplete-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AutocompleteSelectComponent,
            multi: true,
        },
    ],
})
export class AutocompleteSelectComponent<T> implements ControlValueAccessor {
    @Input()
    dataSource: any[]

    @Input()
    optionTemplate: string

    @Input()
    placeholder = ''

    @Output()
    readonly searchChanged = new EventEmitter<string>()

    @Output()
    readonly optionClicked = new EventEmitter<T>()

    value: T = null

    // eslint-disable-next-line
    generateOption(data: unknown) {
        return eval(this.optionTemplate)
    }

    onSearchChanged(searchText: string) {
        this.searchChanged.emit(searchText)
    }

    onChange: (value: T) => void
    onTouched: () => void

    registerOnChange(fn: (value: T) => void): void {
        this.onChange = fn
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn
    }

    writeValue(obj: T): void {
        this.value = obj
    }

    onInput(value: T) {
        this.value = value
        this.optionClicked.emit(value)
    }
}
