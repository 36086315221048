import { Component, OnInit } from '@angular/core'
import packageJson from '../../../../../package.json'
import { TypeReferenceDto } from '../../../sunleon/data-access/http/dto/type-reference-dto'
import { ReferenceService } from '../../../sunleon/data-access/http/reference.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  version = packageJson.version
  versionMoteur: string
  constructor(
    private referenceService: ReferenceService,
  ) {}

  ngOnInit(): void {
    this.referenceService.getVersionMoteur().subscribe((version) => {
      this.versionMoteur = "Version du moteur de règles : " + version
    })
  }

}
