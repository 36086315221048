import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { ResponseDTO } from '../../model/response-dto'
import { AddressDto } from '../../model/address-dto'
import { AddressGoogleDto, GeoCodeGoogleDto} from '../../model/address-google-dto'
import { environment } from '../../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class GeocodingService {
  sApiGeoPortail = 'https://wxs.ign.fr/essentiels/geoportail/geocodage/rest/0.1/completion'

  constructor(private http: HttpClient) {}

  autoCompleteSearch(searchText: string): Observable<AddressDto[]> {
    return this.http
      .get<ResponseDTO<AddressDto>>(this.sApiGeoPortail + searchText, {
        params: {
          text: searchText,
          maximumResponses: '10',
          type: 'PositionOfInterest,StreetAddress',
        },
      })
      .pipe(map((response) => response.results.map((result) => ({ id: [result.x, result.y], ...result }))))
  }

  autoCompleteGoogleSearch(searchText: string): Observable<AddressGoogleDto[]> {
    const sUrl = environment.baseUrl + '/geocode/autocomplete?search=' + searchText
    return this.http
      .get<AddressGoogleDto[]>(sUrl)
      .pipe(map((response) => response.map((response) => ({ id: response.place_id, ...response }))))
  }

  geoCodeGoogle(placeId: string): Observable<GeoCodeGoogleDto> {
    const sUrl = environment.baseUrl + '/geocode?place_id=' + placeId
    return this.http.get<GeoCodeGoogleDto>(sUrl)
  }
  
}


