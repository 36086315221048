import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { scrollToInvalidElement } from '../../../shared/utils/utils'
import { ToastrService } from 'ngx-toastr'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { saveAs } from 'file-saver'
import { ReferenceService } from '../../data-access/http/reference.service'
import { BilanEnergetiqueService } from '../../data-access/http/bilan-energetique.service'
import { SunleonService } from '../../data-access/http/sunleon.service'
import { CsvService } from '../../data-access/http/csv.service'
import { FileUploadService } from '../../data-access/http/file-upload.service'
import { TypeReferenceDto } from '../../data-access/http/dto/type-reference-dto'
import { BilanEnergetiqueDto } from '../../data-access/http/dto/bilan-energetique-dto'
import { SessionZoneSaveDto } from '../../data-access/http/dto/session-zone-save-dto'
import { SessionCommunSaveDto } from '../../data-access/http/dto/session-commun-save-dto'
import { SessionSaveDto } from '../../data-access/http/dto/session-save-dto'
import { AuthService } from '../../../core/services/auth.service'
import { User } from '../../../shared/models/user'

@Component({
  selector: 'app-sunleon-form',
  templateUrl: './sunleon-form.component.html',
  styleUrls: ['./sunleon-form.component.scss'],
})
export class SunleonFormComponent implements OnInit, OnDestroy {
  constructor(
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private referenceService: ReferenceService,
    private bilanEnergetiqueService: BilanEnergetiqueService,
    private sunLeonService: SunleonService,
    private toastr: ToastrService,
    public modalService: NgbModal,
    private csvService: CsvService,
    private fileUploadService: FileUploadService,
  ) {}

  user: User
  message = ''

  @ViewChild('content') modalContent: any

  @ViewChild('contentSuccess') modalContentSuccess: any

  selectedFile: File | undefined

  subscription = new Subscription()
  formIsSubmitting: boolean = false
  formModalIsSubmitting: boolean = false
  modeValorisations: TypeReferenceDto[] = []
  typePoses: TypeReferenceDto[] = []
  typeToitures: TypeReferenceDto[] = []
  typePlaces: TypeReferenceDto[] = []
  typeParkings: TypeReferenceDto[] = []
  dureeContrats: TypeReferenceDto[] = []
  prmCodes: BilanEnergetiqueDto[] = []
  typeGammePanneaux: TypeReferenceDto[] = []
  typeGammeOnduleurs: TypeReferenceDto[] = []

  status: string | undefined
  processIdEnregistrementSimulation: string | undefined
  processIdCreationCsv: string | undefined

  apiMessageSuccess : string = '';

  sunleonForm = this.formBuilder.group({
    nomClient: ['', [Validators.required]],
    nomProjet: ['', [Validators.required]],
    numMonday: [''],
    latitude: [null, [Validators.required]],
    longitude: [null, [Validators.required]],
    departement: [null, [Validators.required, Validators.pattern('^(?!00|20|96|97|98|99)[0-9]{2}$|^(2[AB])$')]],
    puissanceSouscriteKVA: [null, [Validators.pattern('^\\d+$')]],
    modeValorisation: [null, [Validators.required]],
    typePoseZone1: ['', [Validators.required]],
    surfaceZone1: [null, [Validators.required, Validators.pattern('^\\d+(\\.\\d{1,2})?$')]],
    azimutZone1: [null, [Validators.required, Validators.pattern('^-?(180|\\d{1,2}|1[0-7]\\d)$')]],
    typeToitureZone1: [''],
    pvReadyZone1: [false],
    inclinaisonZone1: [null, [Validators.pattern('^\\d+$')]],
    typeVehiculeParkingZone1: [''],
    typePlaceParkingZone1: [''],
    typePoseZone2: [''],
    surfaceZone2: [null, [Validators.pattern('^\\d+(\\.\\d{1,2})?$')]],
    azimutZone2: [null, [Validators.pattern('^-?(180|\\d{1,2}|1[0-7]\\d)$')]],
    typeToitureZone2: [''],
    pvReadyZone2: [false],
    inclinaisonZone2: [null, [Validators.pattern('^\\d+$')]],
    typeVehiculeParkingZone2: [''],
    typePlaceParkingZone2: [''],
    typePoseZone3: [''],
    surfaceZone3: [null, [Validators.pattern('^\\d+(\\.\\d{1,2})?$')]],
    azimutZone3: [null, [Validators.pattern('^-?(180|\\d{1,2}|1[0-7]\\d)$')]],
    typeToitureZone3: [''],
    pvReadyZone3: [false],
    inclinaisonZone3: [null, [Validators.pattern('^\\d+$')]],
    typeVehiculeParkingZone3: [''],
    typePlaceParkingZone3: [''],
    prixApportAffaireHtWc: [null],
    travauxDiversHT: [null],
    travauxVrdHT: [null],
    travauxDistanceVoirieVrd: [null],
    prm: [null],
    tarifHch: [null],
    tarifHph: [null],
    tarifHpt: [null],
    tarifHpb: [null],
    tarifHcb: [null],
    dureeContrat: [null, [Validators.required]],
    distanceRaccordementPublic: [null],
    typeGammePanneau:['CRE', [Validators.required]],
    typeGammeOnduleur:['STRING', [Validators.required]],
    coffretDc: [false],
    paratonnerre: [false],
    aerodrome: [false],
    dispositifNonInjection:[false],
    dispoTGBT:[false],
    automate:[false],
    deposeCandelabre:[null],
    poseCandelabre:[null],
    eclairageSousOmbriere:[false],
    nbAbattageArbres: [null],
    mlClotureSol: [null],
    nbPortailsSol: [null],
    modeDebug:[false],
    forcerSoulteEtRenteInialeVT:[false],
  })

  // Méthode appelée lorsqu'un bouton radio est cliqué
  onRadioClick(code: string | null, controleName: string) {
    // @ts-ignore
    this.sunleonForm.get(controleName).setValue(code)
  }

  openModal() {
    this.modalService
      .open(this.modalContent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false })
      .result.then(
        () => {
          // Traitement à effectuer lorsque le modal est fermé (si nécessaire)
          this.formModalIsSubmitting = false
        },
        () => {
          // Traitement à effectuer si le modal est fermé avec un rejet (si nécessaire)
          this.formModalIsSubmitting = false
        }
      )
  }

  onFileSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement
    if (inputElement?.files) {
      this.selectedFile = inputElement.files[0]
    }
  }

  onSubmit(): void {
    if (this.selectedFile) {
      this.formModalIsSubmitting = true
      this.fileUploadService.uploadFile(this.selectedFile).subscribe({
        next:(data: any) => {
          this.processIdEnregistrementSimulation = data.message
          this.monitorProcessStatus(this.processIdEnregistrementSimulation)
        },
        error:() => {
          this.formModalIsSubmitting = false
          this.modalService.dismissAll()
        }}
      )
    } else {
      this.toastr.error('Aucun fichier sélectionné')
      this.formModalIsSubmitting = false
      this.modalService.dismissAll()
    }
  }

  monitorProcessStatus(processId: string) {
    this.fileUploadService.getProcessStatus(processId).subscribe((result) => {
      this.status = result.status
      if (this.status === 'COMPLETED') {
        this.formModalIsSubmitting = false
        this.toastr.success('Le fichier CSV a été importé avec le code PRM : ' + result.message)
        this.sunleonForm.get('prm').setValue(result.message)
        this.modalService.dismissAll()
        this.processIdEnregistrementSimulation = undefined
      } else {
        if (this.status === 'FAILED') {
          this.formModalIsSubmitting = false
          this.toastr.error(result.message)
          this.modalService.dismissAll()
          this.processIdEnregistrementSimulation = undefined
        } else {
          this.toastr.info(result.message)
        }
      }
    })
  }

  ngOnInit(): void {
    this.user = this.authService.connectedUser
    this.referenceService.getModeValorisation().subscribe((data) => {
      this.modeValorisations = data
    })
    this.referenceService.getTypePose().subscribe((data) => {
      this.typePoses = data
    })
    this.referenceService.getTypeToiture().subscribe((data) => {
      this.typeToitures = data
    })
    this.referenceService.getTypeParking().subscribe((data) => {
      this.typeParkings = data
    })
    this.referenceService.getTypePlace().subscribe((data) => {
      this.typePlaces = data
    })
    this.referenceService.getDureeContrat().subscribe((data) => {
      this.dureeContrats = data
    })
    this.bilanEnergetiqueService.getPrm().subscribe((data) => {
      this.prmCodes = data
    })

    this.referenceService.getTypeGammePanneau().subscribe((data) => {
      this.typeGammePanneaux = data
    })

    this.referenceService.getTypeGammeOnduleur().subscribe((data) => {
      this.typeGammeOnduleurs = data
    })

    this.initTypePoseZone(
      'typePoseZone1',
      'typePoseZone2',
      'typePoseZone3',
      'deposeCandelabre',
      'poseCandelabre',
      'eclairageSousOmbriere',
      'nbAbattageArbres',
      'mlClotureSol',
      'nbPortailsSol',
      'surfaceZone1',
      'azimutZone1',
      'typeToitureZone1',
      'pvReadyZone1',
      'inclinaisonZone1',
      'typeVehiculeParkingZone1',
      'typePlaceParkingZone1'
    )

    this.initTypePoseZone(
      'typePoseZone2',
      'typePoseZone1',
      'typePoseZone3',
      'deposeCandelabre',
      'poseCandelabre',
      'eclairageSousOmbriere',
      'nbAbattageArbres',
      'mlClotureSol',
      'nbPortailsSol',
      'surfaceZone2',
      'azimutZone2',
      'typeToitureZone2',
      'pvReadyZone2',
      'inclinaisonZone2',
      'typeVehiculeParkingZone2',
      'typePlaceParkingZone2'
    )
    this.initTypePoseZone(
      'typePoseZone3',
      'typePoseZone1',
      'typePoseZone2',
      'deposeCandelabre',
      'poseCandelabre',
      'eclairageSousOmbriere',
      'nbAbattageArbres',
      'mlClotureSol',
      'nbPortailsSol',
      'surfaceZone3',
      'azimutZone3',
      'typeToitureZone3',
      'pvReadyZone3',
      'inclinaisonZone3',
      'typeVehiculeParkingZone3',
      'typePlaceParkingZone3'
    )
    this.initModeValorisation(
      'modeValorisation',
      'prm',
      'puissanceSouscriteKVA',
      'tarifHch',
      'tarifHph',
      'tarifHpt',
      'tarifHpb',
      'tarifHcb',
      'distanceRaccordementPublic',
      'travauxDistanceVoirieVrd',
      'dispositifNonInjection',
      'dispoTGBT',
      'automate'
    )

    this.initGammeOnduleur(
      'typeGammeOnduleur',
      'coffretDc',
      'paratonnerre')
  }

  initTypePoseZone(
    typePose: string,
    typePoseAutreZone1: string,
    typePoseAutreZone2: string,
    deposeCandelabre: string,
    poseCandelabre: string,
    eclairageSousOmbriere: string,
    nbAbattageArbres:string,
    mlClotureSol:string,
    nbPortailsSol:string,
    surfaceZone: string,
    azimutZone: string,
    typeToitureZone: string,
    pvReadyZone: string,
    inclinaisonZone: string,
    typeVehiculeParkingZone: string,
    typePlaceParkingZone: string

  ) {
    this.sunleonForm.get(typePose).valueChanges.subscribe((typePoseZone) => {
      const constSurfaceZone = this.sunleonForm.get(surfaceZone)
      const constAzimutZone = this.sunleonForm.get(azimutZone)

      const constTypeToitureZone = this.sunleonForm.get(typeToitureZone)
      const constPvReadyZone = this.sunleonForm.get(pvReadyZone)
      const constInclinaisonZone = this.sunleonForm.get(inclinaisonZone)
      const consttypeVehiculeParkingZone = this.sunleonForm.get(typeVehiculeParkingZone)
      const consttypePlaceParkingZone = this.sunleonForm.get(typePlaceParkingZone)

      const constTypePoseAutreZone1 = this.sunleonForm.get(typePoseAutreZone1)
      const constTypePoseAutreZone2 = this.sunleonForm.get(typePoseAutreZone2)

      const constDeposeCandelabre = this.sunleonForm.get(deposeCandelabre)
      const constPoseCandelabre = this.sunleonForm.get(poseCandelabre)
      const constEclairageSousOmbriere = this.sunleonForm.get(eclairageSousOmbriere)

      const constNbAbattageArbres = this.sunleonForm.get(nbAbattageArbres)

      const constMlClotureSol = this.sunleonForm.get(mlClotureSol)
      const constNbPortailsSol = this.sunleonForm.get(nbPortailsSol)

      if (typePoseZone) {
        constSurfaceZone.setValidators([Validators.required, Validators.pattern('^\\d+(\\.\\d{1,2})?$')])
        constAzimutZone.setValidators([Validators.required, Validators.pattern('^-?(180|\\d{1,2}|1[0-7]\\d)$')])

        /** Si aucune pose OMBRIERE alors les 3 champs Capex par type pose doit être initialisés **/
        if (typePoseZone != 'OMBRIERE' && constTypePoseAutreZone1.value != 'OMBRIERE' && constTypePoseAutreZone2.value != 'OMBRIERE') {
            constDeposeCandelabre.reset()
            constPoseCandelabre.reset()
            constEclairageSousOmbriere.setValue(false)
            constNbAbattageArbres.reset()
        }

        /** Si aucune pose SOL alors les 2 champs Capex par type pose doit être initialisés **/
        if (typePoseZone != 'SOL' && constTypePoseAutreZone1.value != 'SOL' && constTypePoseAutreZone2.value != 'SOL') {
          constMlClotureSol.reset()
          constNbPortailsSol.reset()
        }

        if (typePoseZone === 'TOITURE') {
          constTypeToitureZone.setValidators([Validators.required])
          constPvReadyZone.setValidators([Validators.required])
          constInclinaisonZone.setValidators([Validators.required])
          constTypeToitureZone.reset()
          constPvReadyZone.reset()
          constInclinaisonZone.reset()

          consttypeVehiculeParkingZone.setValidators([Validators.nullValidator])
          consttypePlaceParkingZone.setValidators([Validators.nullValidator])
          consttypeVehiculeParkingZone.reset()
          consttypePlaceParkingZone.reset()
        }
        if (typePoseZone === 'OMBRIERE') {
          consttypeVehiculeParkingZone.setValidators([Validators.required])
          consttypeVehiculeParkingZone.reset()
          consttypePlaceParkingZone.reset()

          constTypeToitureZone.setValidators([Validators.nullValidator])
          constPvReadyZone.setValidators([Validators.nullValidator])
          constInclinaisonZone.setValidators([Validators.nullValidator])

          constTypeToitureZone.reset()
          constPvReadyZone.reset()
          constInclinaisonZone.reset()
        }

        if (typePoseZone === 'SOL') {
          constTypeToitureZone.setValidators([Validators.nullValidator])
          constPvReadyZone.setValidators([Validators.nullValidator])
          constInclinaisonZone.setValidators([Validators.nullValidator])
          consttypeVehiculeParkingZone.setValidators([Validators.nullValidator])
          consttypePlaceParkingZone.setValidators([Validators.nullValidator])

          constTypeToitureZone.reset()
          constPvReadyZone.reset()
          constInclinaisonZone.reset()
          consttypeVehiculeParkingZone.reset()
          consttypePlaceParkingZone.reset()
        }
      } else {
        constSurfaceZone.setValidators([Validators.pattern('^\\d+(\\.\\d{1,2})?$')])
        constAzimutZone.setValidators([Validators.pattern('^-?(180|\\d{1,2}|1[0-7]\\d)$')])
        constTypeToitureZone.setValidators([Validators.nullValidator])
        constPvReadyZone.setValidators([Validators.nullValidator])
        constInclinaisonZone.setValidators([Validators.nullValidator])
        consttypeVehiculeParkingZone.setValidators([Validators.nullValidator])
        consttypePlaceParkingZone.setValidators([Validators.nullValidator])

        constSurfaceZone.reset()
        constAzimutZone.reset()
        constTypeToitureZone.reset()
        constPvReadyZone.reset()
        constInclinaisonZone.reset()
        consttypeVehiculeParkingZone.reset()
        consttypePlaceParkingZone.reset()
      }
    })

    this.sunleonForm.get(typeVehiculeParkingZone).valueChanges.subscribe((typeVehiculeParking) => {
      const constTypeVehiculeParkingZone = this.sunleonForm.get(typeVehiculeParkingZone)
      const constTypePlaceParkingZone = this.sunleonForm.get(typePlaceParkingZone)
      constTypeVehiculeParkingZone.setValidators([Validators.required])
      if (typeVehiculeParking === 'VL') {
        constTypePlaceParkingZone.setValidators([Validators.required])
      } else {
        constTypePlaceParkingZone.reset()
      }
    })
  }

  initModeValorisation(
    modeValorisation: string,
    prmAC: string,
    puissanceSouscriteKVAac: string,
    tarifHchAc: string,
    tarifHphAc: string,
    tarifHptAc: string,
    tarifHpbAc: string,
    tarifHcbAc: string,
    distanceRaccordementPublic: string,
    distanceVoirieVRD: string,
    dispositifNonInjectionAC: string,
    dispoTGBTAC: string,
    automateAC: string
  ) {
    this.sunleonForm.get(modeValorisation).valueChanges.subscribe((mode) => {
      const prm = this.sunleonForm.get(prmAC)
      const puissanceSouscriteKVA = this.sunleonForm.get(puissanceSouscriteKVAac)
      const tarifHch = this.sunleonForm.get(tarifHchAc)
      const tarifHph = this.sunleonForm.get(tarifHphAc)
      const tarifHpt = this.sunleonForm.get(tarifHptAc)
      const tarifHpb = this.sunleonForm.get(tarifHpbAc)
      const tarifHcb = this.sunleonForm.get(tarifHcbAc)
      const raccordementEnedis = this.sunleonForm.get(distanceRaccordementPublic)
      const distanceVoirie = this.sunleonForm.get(distanceVoirieVRD)
      const dispositifNonInjection = this.sunleonForm.get(dispositifNonInjectionAC)
      const dispoTGBT = this.sunleonForm.get(dispoTGBTAC)
      const automate = this.sunleonForm.get(automateAC)

      if (mode === 'AC') {
        prm.setValidators([Validators.required])
        puissanceSouscriteKVA.setValidators([Validators.required])
        tarifHch.setValidators([Validators.required])
        tarifHph.setValidators([Validators.required])
        tarifHpt.setValidators([Validators.required])
        tarifHpb.setValidators([Validators.required])
        tarifHcb.setValidators([Validators.required])
        raccordementEnedis.setValidators([Validators.nullValidator])
        raccordementEnedis.reset()
      } else {
        prm.setValidators([Validators.nullValidator])
        puissanceSouscriteKVA.setValidators([Validators.nullValidator])
        tarifHch.setValidators([Validators.nullValidator])
        tarifHph.setValidators([Validators.nullValidator])
        tarifHpt.setValidators([Validators.nullValidator])
        tarifHpb.setValidators([Validators.nullValidator])
        tarifHcb.setValidators([Validators.nullValidator])
        prm.reset()
        puissanceSouscriteKVA.reset()
        distanceVoirie.reset()
        tarifHch.reset()
        tarifHph.reset()
        tarifHpt.reset()
        tarifHpb.reset()
        tarifHcb.reset()
        raccordementEnedis.setValidators([Validators.required])
        dispositifNonInjection.setValue(false)
        dispoTGBT.setValue(false)
        automate.setValue(false)
      }
    })
  }

  initGammeOnduleur(
    gammeOnduleur: string,
    coffretDC: string,
    paratonnerre: string
  ) {
    this.sunleonForm.get(gammeOnduleur).valueChanges.subscribe((onduleur) => {
      const constCoffretDC = this.sunleonForm.get(coffretDC)
      const constParatonnerre = this.sunleonForm.get(paratonnerre)
      if (onduleur != 'STRING') {
        constCoffretDC.setValue(false)
        constParatonnerre.setValue(false)
      }
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  getSessionSaveFromForm() {
    const formValue = this.sunleonForm.value
    const zoneDto: SessionZoneSaveDto[] = []
    const communDto: SessionCommunSaveDto = {
      nomClient: formValue.nomClient,
      nomProjet: formValue.nomProjet,
      numMonday: formValue.numMonday,
      latitude: formValue.latitude,
      longitude: formValue.longitude,
      departement: formValue.departement,
      puissanceSouscriteKVA: formValue.puissanceSouscriteKVA,
      modeValorisation: formValue.modeValorisation,
      prixApportAffaireHtWc: formValue.prixApportAffaireHtWc === null ? 0 : formValue.prixApportAffaireHtWc,
      travauxDiversHT: formValue.travauxDiversHT === null ? 0 : formValue.travauxDiversHT,
      travauxDistanceVoirieVrd: formValue.travauxDistanceVoirieVrd === null ? 0 : formValue.travauxDistanceVoirieVrd,
      prm: formValue.prm,
      tarifHch: formValue.tarifHch,
      tarifHph: formValue.tarifHph,
      tarifHpt: formValue.tarifHpt,
      tarifHpb: formValue.tarifHpb,
      tarifHcb: formValue.tarifHcb,
      dureeContrat: formValue.dureeContrat,
      distanceRaccordementPublic: formValue.distanceRaccordementPublic,
      typeGammePanneau: formValue.typeGammePanneau,
      typeGammeOnduleur: formValue.typeGammeOnduleur,
      coffretDc: formValue.coffretDc,
      paratonnerre: formValue.paratonnerre,
      aerodrome: formValue.aerodrome,
      dispositifNonInjection: formValue.dispositifNonInjection,
      dispoTGBT: formValue.dispoTGBT,
      automate: formValue.automate,
      deposeCandelabre: formValue.deposeCandelabre === null ? 0 : formValue.deposeCandelabre,
      poseCandelabre: formValue.poseCandelabre === null ? 0 : formValue.poseCandelabre,
      eclairageSousOmbriere: formValue.eclairageSousOmbriere,
      nbAbattageArbres: formValue.nbAbattageArbres === null ? 0 : formValue.nbAbattageArbres,
      mlClotureSol: formValue.mlClotureSol === null ? 0 : formValue.mlClotureSol,
      nbPortailsSol: formValue.nbPortailsSol === null ? 0 : formValue.nbPortailsSol,
      modeDebug : formValue.modeDebug,
      forcerSoulteEtRenteInialeVT: formValue.forcerSoulteEtRenteInialeVT
    }

    zoneDto.push({
      id: 1,
      typePose: formValue.typePoseZone1,
      surface: formValue.surfaceZone1,
      azimut: formValue.azimutZone1,
      typeToiture: formValue.typeToitureZone1 === '' ? null : formValue.typeToitureZone1,
      pvReady: formValue.pvReadyZone1,
      inclinaison: formValue.inclinaisonZone1,
      typeVehiculeParking: formValue.typeVehiculeParkingZone1 === '' ? null : formValue.typeVehiculeParkingZone1,
      typePlaceParking: formValue.typePlaceParkingZone1 === '' ? null : formValue.typePlaceParkingZone1,
    })

    if (formValue.typePoseZone2 != '') {
      zoneDto.push({
        id: 2,
        typePose: formValue.typePoseZone2,
        surface: formValue.surfaceZone2,
        azimut: formValue.azimutZone2,
        typeToiture: formValue.typeToitureZone2 === '' ? null : formValue.typeToitureZone2,
        pvReady: formValue.pvReadyZone2,
        inclinaison: formValue.inclinaisonZone2,
        typeVehiculeParking: formValue.typeVehiculeParkingZone2 === '' ? null : formValue.typeVehiculeParkingZone2,
        typePlaceParking: formValue.typePlaceParkingZone2 === '' ? null : formValue.typePlaceParkingZone2,
      })
    }

    if (formValue.typePoseZone3 != '') {
      zoneDto.push({
        id: 3,
        typePose: formValue.typePoseZone3,
        surface: formValue.surfaceZone3,
        azimut: formValue.azimutZone3,
        typeToiture: formValue.typeToitureZone3 === '' ? null : formValue.typeToitureZone3,
        pvReady: formValue.pvReadyZone3,
        inclinaison: formValue.inclinaisonZone3,
        typeVehiculeParking: formValue.typeVehiculeParkingZone3 === '' ? null : formValue.typeVehiculeParkingZone3,
        typePlaceParking: formValue.typePlaceParkingZone3 === '' ? null : formValue.typePlaceParkingZone3,
      })

    }

    return {
      commun: communDto,
      zones: zoneDto,
    } as SessionSaveDto
  }

  saveSimulation() {
    if (!this.sunleonForm.valid) {
      this.sunleonForm.markAllAsTouched()
      scrollToInvalidElement()
      return
    }

    this.formIsSubmitting = true
    const simulationSave = this.getSessionSaveFromForm()
    this.sunLeonService.transformSession(simulationSave).subscribe({
      next:(data: any) => {
        // Divisez la chaîne en utilisant le caractère "|"
        const elements: string[] = data.message.split('|')
        // Maintenant, elements[0] contient "Premier Élément" et elements[1] contient "Deuxième Élément"
        // Vous pouvez utiliser ces éléments comme vous le souhaitez
        this.processIdEnregistrementSimulation = elements[0]
        this.processIdCreationCsv = elements[1]
        this.monitorProcessStatusSession(this.processIdEnregistrementSimulation, this.processIdCreationCsv)
      },
      error:() => {
        this.formIsSubmitting = false
        this.modalService.dismissAll()
      }}
    )
  }

  openModalSuccess() {
    this.modalService
      .open(this.modalContentSuccess, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false })
      .result.then(
      () => {
        this.apiMessageSuccess = '';
        // Traitement à effectuer lorsque le modal est fermé (si nécessaire)
        this.formModalIsSubmitting = false
      },
      () => {
        // Traitement à effectuer si le modal est fermé avec un rejet (si nécessaire)
        this.apiMessageSuccess = '';
        this.formModalIsSubmitting = false
      }
    )
  }

  monitorProcessStatusSession(processIdEnregistrementSimulation: string, processIdCreationCsv: string) {
    this.sunLeonService.getProcessStatusSession(processIdEnregistrementSimulation).subscribe((result) => {
      this.status = result.status
      if (this.status === 'COMPLETED') {
        this.toastr.info(result.message)
        this.sunLeonService
          .checkSecondProcessStatus(processIdCreationCsv, processIdEnregistrementSimulation)
          .subscribe((result) => {
            this.status = result.status
            if (this.status === 'COMPLETED') {
              if (result.urlCsv != null) {
                saveAs(result.urlCsv, result.nomCsv)
                this.toastr.success(result.message)
                this.apiMessageSuccess = result.message;
                this.openModalSuccess();
                this.formIsSubmitting = false
              }
              this.processIdCreationCsv = undefined
            } else {
              if (this.status === 'FAILED') {
                this.formIsSubmitting = false
                this.toastr.error(result.message)
                this.processIdCreationCsv = undefined
              } else {
                this.toastr.info(result.message)
              }
            }
          })
      } else {
        if (this.status === 'FAILED') {
          this.formIsSubmitting = false
          this.toastr.error(result.message)
          this.processIdEnregistrementSimulation = undefined
        } else {
          this.toastr.info(result.message)
        }
      }
    })
  }

}

