import { Component, OnInit } from '@angular/core'
import { ReferenceService } from '../../../sunleon/data-access/http/reference.service'
import { TypeReferenceDto } from '../../../sunleon/data-access/http/dto/type-reference-dto'

@Component({
  selector: 'app-parametre-onglet',
  templateUrl: './parametre-onglet.component.html',
  styleUrls: ['./parametre-onglet.component.scss'],
})
export class ParametreOngletComponent implements OnInit {
  constructor(
    private referenceService: ReferenceService) {}

  referenceFamille: TypeReferenceDto[]

  ngOnInit(): void {
    this.referenceService.getFamille().subscribe((familleList) => {
      this.referenceFamille = familleList
    })
  }

}
