import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../../environments/environment'
import { ParametreDto } from './dto/parametre-dto'
import { ParametreTableauDto } from './dto/parametre-tableau-dto'
import { ParametreTableauSaveDto } from './dto/parametre-tableau-save-dto'
import { ParametreSpecfiqueBddEnedisDto } from './dto/parametre-specfique-bdd-enedis-dto'

@Injectable({
  providedIn: 'root',
})

export class ParametreService {
  public readonly familleParDefaut = "bilaneco"
  constructor(private http: HttpClient) {
  }

  getParametresByFamille(famille: string): Observable<ParametreDto[]> {
    const path = `${environment.baseUrl}/parametres/famille/${famille}`
    return this.http.get<ParametreDto[]>(path)
  }

  getParametresTableau(codeParametre: string): Observable<ParametreTableauDto> {
    const path = `${environment.baseUrl}/parametres/tableau/${codeParametre}`
    return this.http.get<ParametreTableauDto>(path)
  }

  saveParametreTypeSimple(parametreDto: ParametreDto, valeur : string) {
    parametreDto.valeur = valeur;
    const path = `${environment.baseUrl}/parametres/` + parametreDto.code
    return this.http.put(path, parametreDto)
  }

  saveParametreTypeTableau(parametreDto: ParametreDto, valeurs : ParametreTableauSaveDto) {
    const path = `${environment.baseUrl}/parametres/tableau/` + parametreDto.code
    return this.http.put(path, valeurs)
  }

  getParametresBddEnedis(codeParametre: string): Observable<ParametreSpecfiqueBddEnedisDto> {
    const path = `${environment.baseUrl}/parametres/specifique/bddenedis/${codeParametre}`
    return this.http.get<ParametreSpecfiqueBddEnedisDto>(path)
  }

  saveParametreBddEnedis(parametreDto: ParametreDto, valeurs : ParametreSpecfiqueBddEnedisDto) {
    const path = `${environment.baseUrl}/parametres/specifique/` + parametreDto.code
    return this.http.put(path, valeurs)
  }

}
