import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { ReferenceService } from '../../../sunleon/data-access/http/reference.service'
import { TypeReferenceDto } from '../../../sunleon/data-access/http/dto/type-reference-dto'
import { NgClass, NgForOf, NgIf } from '@angular/common'

@Component({
  selector: 'app-form-capex-generalises',
  templateUrl: './form-capex-generalises.component.html',
  styleUrls: ['./form-capex-generalises.component.scss'],
  standalone:true,
  imports: [NgIf, FormsModule, NgForOf, ReactiveFormsModule, NgClass],
})

export class FormCapexGeneralisesComponent implements OnInit {

  @Output()
  formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  capexGeneralisesForm: FormGroup
  typeGammePanneaux: TypeReferenceDto[] = []
  typeGammeOnduleurs: TypeReferenceDto[] = []

  constructor(
    private referenceService: ReferenceService,
    private formBuilder: FormBuilder) {

    this.capexGeneralisesForm = this.formBuilder.group({
      typeGammePanneau:['CRE', [Validators.required]],
      typeGammeOnduleur:['STRING', [Validators.required]],
      coffretDc: [false],
      paratonnerre: [false],
      aerodrome: [false],
      prixApportAffaireHtWc: [null],
      travauxDiversHT: [null],
    })

  }

  ngOnInit(): void {
    this.referenceService.getTypeGammePanneau().subscribe((data) => {
      this.typeGammePanneaux = data
    })
    this.referenceService.getTypeGammeOnduleur().subscribe((data) => {
      this.typeGammeOnduleurs = data
    })
    this.initGammeOnduleur('typeGammeOnduleur', 'coffretDc','paratonnerre')
    this.formReady.emit(this.capexGeneralisesForm)
  }

  initGammeOnduleur(gammeOnduleur: string,coffretDC: string,paratonnerre: string
  ) {
    this.capexGeneralisesForm.get(gammeOnduleur).valueChanges.subscribe((onduleur) => {
      const constCoffretDC = this.capexGeneralisesForm.get(coffretDC)
      const constParatonnerre = this.capexGeneralisesForm.get(paratonnerre)
      if (onduleur != 'STRING') {
        constCoffretDC.setValue(false)
        constParatonnerre.setValue(false)
      }
    })
  }

}
