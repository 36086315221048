export class User {
  nom: string
  prenom: string
  administrateur:boolean

  constructor(init?: Partial<User>) {
    Object.assign(this, init)
  }

  getInitiales() {
    return (this.prenom[0] + this.nom[0]).toUpperCase()
  }

}
