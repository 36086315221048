<ng-select
    (search)="onSearchChanged($event.term)"
    (clear)="onSearchChanged('')"
    (close)="onSearchChanged('')"
    (change)="onInput($event)"
    [placeholder]='placeholder'
    notFoundText="Aucun résultat">
  <ng-template >
    <!-- Icône personnalisée pour la loupe -->
    <i class="bi bi-search"></i>
  </ng-template>
    <ng-option *ngFor="let data of dataSource" [value]="$any(data).id"> {{ generateOption(data) }}</ng-option>
</ng-select>
