<div class="container">
  <div class="sunleon-container row">
    <div class="sunleon-notauthenticated col-12">
      <div class="d-flex flex-column align-items-center my-5">
        <h1>Utilisateur non autorisé</h1>
        <h2>Votre utilisateur n'est pas autorisé à accéder à cette application. Contactez votre administrateur.</h2>
      </div>
      <div class="bouton">
        <button class="btn btn-primary" (click)="logout()">Changer de compte</button>
      </div>
    </div>
  </div>
</div>
