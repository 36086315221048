import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, switchMap, takeWhile, timer } from 'rxjs'
import { SessionSaveDto } from './dto/session-save-dto'
import { environment } from '../../../../environments/environment'
import { ProcessResultDto } from './dto/ProcessResultDto'

@Injectable({
  providedIn: 'root',
})

export class SunleonService {

  private readonly pollingInterval = 5000; // Intervalle de vérification en millisecondes

  constructor(private http: HttpClient) {
  }

  downloadCSV(sunleonSave: SessionSaveDto) {
    const path = `${environment.baseUrl}/sessions/csv`
    return this.http.post(path, sunleonSave, {
      observe: 'response',
      responseType: 'blob'
    })
  }

  transformSession(sunleonSave: SessionSaveDto) {
    const path = `${environment.baseUrl}/sessions/transformSession`
    return this.http.post(path, sunleonSave)
  }

  getProcessStatusSession(processId: string) {
    const path = `${environment.baseUrl}/sessions/transformSession/status/${processId}`
    return timer(0, 9000).pipe( // Interroge toutes les 9 secondes
      switchMap(() => this.http.get<ProcessResultDto>(path)),
      takeWhile(result => result.status !== 'COMPLETED' && result.status !== 'FAILED', true) // Se termine une fois que le statut est COMPLETED ou FAILED
    );
  }

  checkSecondProcessStatus(processIdCreationCsv: string, processId_Evenement: string): Observable<ProcessResultDto> {
    const path = `${environment.baseUrl}/sessions/transformSession/status/csv/${processIdCreationCsv}/${processId_Evenement}`
    return timer(0, 9000).pipe( // Interroge toutes les 9 secondes
      switchMap(() => this.http.get<ProcessResultDto>(path)),
      takeWhile(result => result.status !== 'COMPLETED' && result.status !== 'FAILED', true) // Se termine une fois que le statut est COMPLETED ou FAILED
    );

  }

}
