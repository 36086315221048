import { Component, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Observable, Subscription, switchMap } from 'rxjs'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ParametreSimpleModalComponent } from '../modals/parametre-simple-modal/parametre-simple-modal.component'
import { TypeEnum } from '../../models/type.enum'
import { ParametreTableauModalComponent } from '../modals/parametre-tableau-modal/parametre-tableau-modal.component'
import { ParametreSpecifiqueBddEnedisModalComponent } from '../modals/parametre-specifique-bdd-enedis-modal/parametre-specifique-bdd-enedis-modal.component'
import { ParametreDto } from '../../data-access/http/dto/parametre-dto'
import { ParametreService } from '../../data-access/http/parametre.service'

@Component({
  selector: 'app-parametre',
  templateUrl: './parametre.component.html',
  styleUrls: ['./parametre.component.scss'],
})
export class ParametreComponent implements OnDestroy{
  iconClicked = false;
  parametres$: Observable<ParametreDto[]>
  readonly modals: any = {
    [TypeEnum.SIMPLE]:  ParametreSimpleModalComponent,
    [TypeEnum.TABLEAU]:  ParametreTableauModalComponent,
    [TypeEnum.SPECIFIQUE]:  ParametreSpecifiqueBddEnedisModalComponent,
  }

  selectedRowIndex: number | null = null;
  private routeSubscription: Subscription;

  constructor(public parametreService: ParametreService,
              private activatedRoute: ActivatedRoute,
              private modalService: NgbModal)
  {

    this.parametres$ = this.activatedRoute.paramMap.pipe(
      switchMap(params => this.parametreService.getParametresByFamille(params.get('famille')))
    )

    // Changement d'URL pour réinitialiser selectedRowIndex à null
    this.routeSubscription = this.activatedRoute.params.subscribe(params => {
      this.selectedRowIndex = null;
    });
  }

  openEditionModal(parametreDto: ParametreDto) {
      const modalRef = this.modalService.open(this.modals[parametreDto.type], {size:'lg'} )
      const modalComponent = modalRef.componentInstance
      modalComponent.parametre = {...parametreDto}
      modalRef.dismissed.subscribe(data => {
        if (data) {
          parametreDto.valeur = data.valeur
          parametreDto.utilisateur = data.utilisateur
          parametreDto.dateModification = data.dateModification
        }
      })
  }

  openTableauEditionModal(parametreDto: ParametreDto) {
    const modalRef = this.modalService.open(this.modals[parametreDto.type], {size:'xl'} )
    const modalComponent = modalRef.componentInstance
    modalComponent.parametre = {...parametreDto}
    modalRef.dismissed.subscribe(data => {
      if (data) {
        parametreDto.utilisateur = data.utilisateur
        parametreDto.dateModification = data.dateModification
      }
    })
  }

  openBddEnedisEditionModal(parametreDto: ParametreDto) {
    const modalRef = this.modalService.open(this.modals[parametreDto.type], {size:'xl'} )
    const modalComponent = modalRef.componentInstance
    modalComponent.parametre = {...parametreDto}
    modalRef.dismissed.subscribe(data => {
      if (data) {
        parametreDto.utilisateur = data.utilisateur
        parametreDto.dateModification = data.dateModification
      }
    })
  }

  handleRowClick(index: number): void {
    // Change la couleur de la ligne ou effectuez d'autres actions
    this.selectedRowIndex = index;
  }

  ngOnDestroy(): void {
    // Désabonnement pour éviter des fuites de mémoire
    this.routeSubscription.unsubscribe();
  }

}
