import { Component, inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NgSelectModule } from '@ng-select/ng-select'
import { AutocompleteSelectComponent } from '../../../shared/ui/autocomplete-select/autocomplete-select.component'
import { GeomapDomainService } from '../../domain/geomap.domain'
import { ToolEnum } from '../../model/tool.enum'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-control-panel',
  standalone: true,
  imports: [CommonModule, NgSelectModule, AutocompleteSelectComponent],
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.scss'],
})
export class ControlPanelComponent {
  private readonly geomapDomainService = inject(GeomapDomainService)

  activeZoneId$: Observable<number> = this.geomapDomainService.activeZoneId$
  activeTool$: Observable<ToolEnum> = this.geomapDomainService.activeTool$
  selectedModeValorisation$: Observable<boolean> = this.geomapDomainService.selectedModeValorisation$
  selectedTypePoseCurrentZone$: Observable<boolean> = this.geomapDomainService.selectedTypePoseCurrentZone$
  selectedOutils$: Observable<boolean> = this.geomapDomainService.selectedOutils$
  selectedDessinOutilsSeulementZone$: Observable<boolean> = this.geomapDomainService.selectedDessinOutilsSeulementZone$
  toolEnum = ToolEnum

  constructor() {}

  disableTools(): void {
    this.geomapDomainService.disableTools()
  }

  activateMeasureAreaTool(): void {
    this.geomapDomainService.activateMeasureAreaTool()
  }

  activateMeasureAzimuthTool(): void {
    this.geomapDomainService.activateMeasureAzimuthTool()
  }

  activateMeasureDistanceTool(): void {
    this.geomapDomainService.activateMeasureDistanceTool()
  }

  activateDeleteTool(): void {
    this.geomapDomainService.activateDeleteTool()
  }

  resetCurrentZone(): void {
    this.geomapDomainService.resetCurrentZone()
  }
  resetDessinModeValorisation(): void {
    this.geomapDomainService.resetDessinModeValorisation()
  }
}
