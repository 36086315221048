import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import { environment } from '../../../../environments/environment'
import { TypeReferenceDto } from './dto/type-reference-dto'

@Injectable({
  providedIn: 'root',
})
export class ReferenceService {
  constructor(private http: HttpClient) {
  }

  getModeValorisation(): Observable<TypeReferenceDto[]> {
    const path = `${environment.baseUrl}/references/mode-valorisation`
    return this.http.get<TypeReferenceDto[]>(path)
  }

  getTypePose(): Observable<TypeReferenceDto[]> {
    const path = `${environment.baseUrl}/references/types-pose`
    return this.http.get<TypeReferenceDto[]>(path)
  }

  getTypeToiture(): Observable<TypeReferenceDto[]> {
    const path = `${environment.baseUrl}/references/types-toiture`
    return this.http.get<TypeReferenceDto[]>(path)
  }

  getTypePlace(): Observable<TypeReferenceDto[]> {
    const path = `${environment.baseUrl}/references/types-place`
    return this.http.get<TypeReferenceDto[]>(path)
  }

  getTypeParking(): Observable<TypeReferenceDto[]> {
    const path = `${environment.baseUrl}/references/types-parking`
    return this.http.get<TypeReferenceDto[]>(path)
  }

  getDureeContrat(): Observable<TypeReferenceDto[]> {
    const path = `${environment.baseUrl}/references/duree-contrat`
    return this.http.get<TypeReferenceDto[]>(path)
  }

  getFamille(): Observable<TypeReferenceDto[]> {
    const path = `${environment.baseUrl}/references/famille`
    return this.http.get<TypeReferenceDto[]>(path)
  }

  getTypeGammePanneau(): Observable<TypeReferenceDto[]> {
    const path = `${environment.baseUrl}/references/gamme-panneau`
    return this.http.get<TypeReferenceDto[]>(path)
  }

  getTypeGammeOnduleur(): Observable<TypeReferenceDto[]> {
    const path = `${environment.baseUrl}/references/gamme-onduleur`
    return this.http.get<TypeReferenceDto[]>(path)
  }

  getTarifAchat(): Observable<TypeReferenceDto[]> {
    const path = `${environment.baseUrl}/references/tarif-achat`
    return this.http.get<TypeReferenceDto[]>(path)
  }

  getVersionMoteur(): Observable<string> {
    const path = `${environment.baseUrl}/version`
    return this.http.get(path, { responseType: 'text' })
  }

}
