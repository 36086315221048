import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export class CustomValidators {
  static requiredIfControlHasValue(controlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value
      const valueIsNotNull = value !== null && value !== undefined
      const comparedControlValue = control.parent.get(controlName)?.value
      const controlIsValid = valueIsNotNull || (!comparedControlValue)
      return controlIsValid ? null :{ required: true }
    }
  }
}
