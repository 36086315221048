import { AuthConfig } from 'angular-oauth2-oidc'
import { environment } from '../../../environments/environment'

export const authConfig: AuthConfig = {
  redirectUri: window.location.origin,
  responseType: 'code',
  oidc: true,
  scope: 'openid',
  resource: window.location.origin,
  showDebugInformation: true,
  disablePKCE: true,
  clientId: environment.oauth.clientId,
  loginUrl: environment.oauth.loginUrl,
  issuer: environment.oauth.issuer,
  logoutUrl: environment.oauth.logoutUrl,
  tokenEndpoint: environment.oauth.tokenEndpoint,
  userinfoEndpoint: environment.oauth.userInfoEndpoint,
  jwks: environment.oauth.jwks,
}
