<app-parametre-onglet></app-parametre-onglet>
<div class="sunleon-container-parametre">
  <div class="container">
    <div class="table-responsive w-100">
      <table *ngIf="parametres$ | async as parametres" class="table table-bordered table-striped">
        <thead class="thead-dark sticky-top">
        <tr>
          <th scope="col">Sous-famille</th>
          <th scope="col">Catégorie</th>
          <th scope="col">Code</th>
          <th scope="col">Valeur</th>
          <th scope="col">Unité</th>
          <th scope="col">Date modification</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let parametreDto of parametres; let i = index"  [ngClass]="{'selected-row': i === selectedRowIndex}" (click)="handleRowClick(i)">
            <th scope="row">
              <!-- Vérifiez si le champ sous-famille est différent de la ligne précédente -->
              <ng-container *ngIf="i === 0 || parametreDto.sousfamille !== parametres[i - 1].sousfamille">
                {{ parametreDto.sousfamille }}
              </ng-container>
            </th>
            <td>
              {{ parametreDto.categorie }}
            </td>
            <td [attr.data-toggle]="'tooltip'" [attr.title]="parametreDto.desc">
              {{ parametreDto.code }}
            </td>
            <td>
              <ng-container *ngIf="parametreDto.type.toLowerCase() === 'simple'">
                {{ parametreDto.valeur }}
              </ng-container>
              <ng-container *ngIf="parametreDto.type.toLowerCase() === 'tableau'">
                Tableau
              </ng-container>
              <ng-container *ngIf="parametreDto.type.toLowerCase() === 'spécifique'">
                Tableau spécifique
              </ng-container>
            </td>
            <td>
              {{ parametreDto.unite }}
            </td>
            <td [attr.data-toggle]="'tooltip'" [attr.title]="parametreDto.utilisateur">
              {{ parametreDto.dateModification | date:'dd/MM/yyyy HH:mm':'':'fr-FR' }}
            </td>
            <td>
              <i *ngIf="parametreDto.type.toLowerCase() === 'simple'" role="button" class="bi bi-pencil-square"
                 [class.icon-color-change]="!iconClicked"
                 (click)="openEditionModal(parametreDto)">
              </i>
              <i *ngIf="parametreDto.type.toLowerCase() === 'tableau'" role="button" class="bi bi-pencil-square"
                 [class.icon-color-change]="!iconClicked"
                 (click)="openTableauEditionModal(parametreDto)">
              </i>
              <i *ngIf="parametreDto.type.toLowerCase() === 'spécifique'" role="button" class="bi bi-pencil-square"
                 [class.icon-color-change]="!iconClicked"
                 (click)="openBddEnedisEditionModal(parametreDto)">
              </i>
            </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

