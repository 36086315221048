import {CanActivateFn, Router} from "@angular/router";
import {inject} from "@angular/core";
import { AuthService } from '../services/auth.service'

export function adminGuard(): CanActivateFn {
  return () => {
    const authService = inject(AuthService)
    const router = inject(Router)
    const user = authService.connectedUser
    if(user.administrateur) {
      return true
    }
    router.navigate(['not-found'])
    return false
  }
}
