import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TypeValeurEnum } from '../../../models/type-valeur.enum'
import { ParametreDto } from '../../../data-access/http/dto/parametre-dto'
import { ParametreService } from '../../../data-access/http/parametre.service'

@Component({
  selector: 'app-parametre-simple-modal',
  templateUrl: './parametre-simple-modal.component.html',
  styleUrls: ['./parametre-simple-modal.component.scss']
})
export class ParametreSimpleModalComponent implements OnInit {

  @Input()
  parametre: ParametreDto
  control: FormControl
  formModalIsSubmitting: boolean = false
  controls: Record<string, FormControl>

  @ViewChild('inputValeur') inputValeur: ElementRef;
  @ViewChild('close') public modal: ParametreSimpleModalComponent;

  constructor(
    private toastr: ToastrService,
    private parametreService: ParametreService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.controls = {
      [TypeValeurEnum.ENTIER.toLowerCase()] : new FormControl(Math.trunc(+this.parametre.valeur), [Validators.required, Validators.pattern(/^\d+$/)]),
      [TypeValeurEnum.DECIMAL.toLowerCase()] : new FormControl(this.parametre.valeur,[Validators.required]),
      [TypeValeurEnum.POURCENTAGE.toLowerCase()] : new FormControl(this.parametre.valeur,  [Validators.required, Validators.pattern(/^(\d{1,2}(\.\d*)?|100(\.0*)?)$/)])
    }
    this.control = this.controls[this.parametre.typevaleur.toLowerCase()]
  }

  onSubmit(): void {
    if (this.control.valid) {
      this.formModalIsSubmitting = true
      this.parametreService.saveParametreTypeSimple(this.parametre, this.control.value).subscribe({
        next:(data: any) => {
          this.toastr.success('Le paramètre ' + this.parametre.code + ' a été enregistré avec succès.')
          this.formModalIsSubmitting = false
          this.activeModal.dismiss(data)
        },
        error:() => {
          this.toastr.error('Erreur lors de l\'enregistrement du paramètre.')
          this.formModalIsSubmitting = false
          this.activeModal.dismiss()
        }}
      )
    }
  }

  fermerModal(): void {
    // Fermez le modal en appelant la méthode close sur l'objet
    this.activeModal.dismiss()
  }
}
