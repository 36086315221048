import { AfterViewInit, Component, ElementRef, inject, Input, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common';
import Map from 'ol/Map'

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit {
  ngAfterViewInit(): void {

    // Create the div
    var scrollDiv = document.createElement("div");
    scrollDiv.className = "scrollbar-measure";
    document.body.appendChild(scrollDiv);

    // Get the scrollbar width
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    console.info(scrollbarWidth);

// Delete the div
    document.body.removeChild(scrollDiv);
  }

  private readonly elementRef = inject(ElementRef)

  @Input() set map(map: Map) {
    map.setTarget(this.elementRef.nativeElement)
  }
}
