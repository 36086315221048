import { Component, inject, OnInit } from '@angular/core'
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private readonly modalConfig = inject(NgbModalConfig)

  ngOnInit(): void {
    this.removeStartupLoader()
    this.modalConfig.backdrop = 'static'
  }

  removeStartupLoader() {
    const startupLoader: HTMLElement | null = document.getElementById('startup-loader')
    if (startupLoader) {
      startupLoader.remove()
    }
  }

}

