import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { from, lastValueFrom, Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { OAuthService } from 'angular-oauth2-oidc'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private oAuthService: OAuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(req, next))
  }

  private async handle(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    if (req.url.match(environment.baseUrl)) {
      req = await this.addAuthorizationHeader(req)
    }
    return lastValueFrom(next.handle(req))
  }

  private async addAuthorizationHeader(req: HttpRequest<any>) {
    await this.refreshToken()

    if (this.oAuthService.hasValidIdToken()) {
      req = req.clone({
        setHeaders: { Authorization: `Bearer ${this.oAuthService.getIdToken()}` },
      })
    }
    return req
  }

  private refreshToken(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (+new Date() >= this.oAuthService.getAccessTokenExpiration() && this.oAuthService.getRefreshToken()) {
        this.oAuthService.refreshToken().then(() => {
          resolve()
        })
      } else {
        resolve()
      }
    })
  }
}
