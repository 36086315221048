import { Component, Input, OnInit } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { TypeReferenceDto } from '../../../../sunleon/data-access/http/dto/type-reference-dto'
import { ParametreDto } from '../../../data-access/http/dto/parametre-dto'
import { ParametreTableauDto } from '../../../data-access/http/dto/parametre-tableau-dto'
import { ParametreService } from '../../../data-access/http/parametre.service'
import { ReferenceService } from '../../../../sunleon/data-access/http/reference.service'
import { ParametreTableauSaveDto } from '../../../data-access/http/dto/parametre-tableau-save-dto'

@Component({
  selector: 'app-parametre-tableau-modal',
  templateUrl: './parametre-tableau-modal.component.html',
  styleUrls: ['./parametre-tableau-modal.component.scss'],
})
export class ParametreTableauModalComponent implements OnInit {
  @Input()
  parametre: ParametreDto
  parametreTableauDto: ParametreTableauDto
  formGroup: FormGroup = this.formBuilder.group({})
  displayTableau: boolean = false
  formModalIsSubmitting: boolean = false
  tarifsAchat: TypeReferenceDto[] = []

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public modalService: NgbModal,
    private parametreService: ParametreService,
    private referenceService: ReferenceService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.parametreService.getParametresTableau(this.parametre.code).subscribe((parametreTableau) => {
      this.parametreTableauDto = parametreTableau
      this.formGroup.addControl('titres', this.generateForm(parametreTableau))
      this.formGroup.addControl('datas', this.generateFormData(parametreTableau))
      this.displayTableau = true
    })

    this.referenceService.getTarifAchat().subscribe((data) => {
      this.tarifsAchat = data
    })
  }

  private generateForm(parametreTableau: ParametreTableauDto): FormArray {
    const formArray: FormArray<FormControl> = this.formBuilder.array([])
    let i = 0
    for (const ligne of parametreTableau.colonnes) {
      if (i === parametreTableau.colonnes.length - 1) {
        formArray.push(
          this.formBuilder.control(ligne.maxInterval, [Validators.required, Validators.pattern(/^9999999$/)])
        )
      } else {
        formArray.push(
          this.formBuilder.control(ligne.maxInterval, [
            Validators.required,
            this.isValidationTranchePrecedente(i),
            this.isValidationTrancheSuivante(i),
          ])
        )
      }
      i++
    }
    return formArray
  }

  private generateFormData(parametreTableau: ParametreTableauDto): FormArray {
    const formArray: FormArray<FormArray> = this.formBuilder.array<FormArray>([])
    for (let i = 0; i < parametreTableau.colonnes[0].data.length; i++) {
      const formArrayData = this.formBuilder.array([])
      let j = 0
      for (const ligne of parametreTableau.colonnes) {
        formArrayData.push(this.formBuilder.control(ligne.data[i], [Validators.required]))
        j++
      }
      formArray.push(formArrayData)
    }
    return formArray
  }

  onSubmit(): void {
    if (this.formGroup.valid) {
      this.formModalIsSubmitting = true
      const dto: ParametreTableauSaveDto = this.revertData(this.formGroup.value)
      dto.parametreDto = this.parametre
      this.formModalIsSubmitting = false
      this.modalService.dismissAll()
      this.parametreService.saveParametreTypeTableau(this.parametre, dto).subscribe({
        next: (data: any) => {
          this.toastr.success('Le paramètre ' + this.parametre.code + ' a été enregistré avec succès.')
          this.formModalIsSubmitting = false
          this.activeModal.dismiss(data)
        },
        error:() => {
          this.toastr.error("Erreur lors de l'enregistrement du paramètre.")
          this.formModalIsSubmitting = false
          this.activeModal.dismiss()
        }}
      )
    }
  }

  revertData(parametreTableauSaveDto: ParametreTableauSaveDto): ParametreTableauSaveDto {
    const result = []
    for (let i = 0; i < parametreTableauSaveDto.datas[0].length; i++) {
      const colonne = []
      for (const ligne of parametreTableauSaveDto.datas) {
        colonne.push(ligne[i])
      }
      result.push(colonne)
    }

    return { ...parametreTableauSaveDto, datas: result }
  }

  // Cette fonction vérifie si le FormControl associé à l'indice donné est invalide
  isInvalidTranche(index: number) {
    const control = (this.formGroup.get('titres') as FormArray).controls[index]
    return control.invalid && (control.dirty || control.touched)
  }

  // Cette fonction vérifie si le FormControl associé à l'indice donné est invalide
  isInvalidDatas(indexLigne: number, index: number) {
    const ligne = (this.formGroup.get('datas') as FormArray).controls[indexLigne] as FormArray
    const data = ligne.controls[index] as FormControl
    return data.errors !== null
  }

  getValidationMessage(index: number) {
    const control = (this.formGroup.get('titres') as FormArray).controls[index]
    if (control.hasError('required')) {
      return 'La tranche est obligatoire'
    } else if (control.hasError('pattern')) {
      return 'La dernière tranche doit être égale à 9999999'
    } else if (control.hasError('isValidationTranchePrecedente')) {
      return 'La tranche doit être supérieure à la tranche précédente'
    } else if (control.hasError('isValidationTrancheSuivante')) {
      return 'La tranche doit être inférieure à la tranche suivante'
    }
    return ''
  }

  isValidationTranchePrecedente(index: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.formGroup.get('titres') !== null) {
        const formArray = this.formGroup.get('titres') as FormArray
        const previousValue = index > 0 ? formArray.controls[index - 1].value : 0
        const currentValue = control.value
        if (previousValue !== null && currentValue <= previousValue) {
          return { isValidationTranchePrecedente: true }
        }
        return null
      }
      return null
    }
  }

  isValidationTrancheSuivante(index: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.formGroup.get('titres') !== null) {
        const formArray = this.formGroup.get('titres') as FormArray
        const suivanteValue = formArray.controls[index + 1].value // index > 0 ? formArray.controls[index + 1].value : 0
        const currentValue = control.value
        if (suivanteValue !== null && currentValue >= suivanteValue) {
          return { isValidationTrancheSuivante: true }
        }
        return null
      }
      return null
    }
  }

  fermerModal(): void {
    // Fermez le modal en appelant la méthode close sur l'objet MatDialogRef
    this.activeModal.dismiss()
  }
}
