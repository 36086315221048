import { LineString, Polygon } from 'ol/geom'
import { getArea, getLength } from 'ol/sphere'

export class MeasureUtils {

    static computeArea(polygon: Polygon): number {
        return Math.round(getArea(polygon) * 100) / 100
    }

    static computeAzimuth(lineString: LineString): number {
        const latitudeDepart: number = lineString.getFirstCoordinate()[0]
        const longitudeDepart: number = lineString.getFirstCoordinate()[1]
        const latitudeArrivee: number = lineString.getLastCoordinate()[0]
        const longitudeArrivee: number = lineString.getLastCoordinate()[1]

      // console.info(Math.round((Math.atan2(longitudeArrivee - longitudeDepart, latitudeArrivee - latitudeDepart) * 180) / Math.PI))
      return this.calculerAzimut(latitudeDepart, longitudeDepart, latitudeArrivee, longitudeArrivee)
    }

    static computeLength(lineString: LineString): number {
        return Math.round(getLength(lineString) * 100) / 100
    }

  static calculerAzimut(departLatitude: number, departLongitude: number, arriveeLatitude: number, arriveeLongitude: number): number {

    // Calculer l'azimut en radians
    const azimuthInRadians = Math.atan2(departLongitude - arriveeLongitude, departLatitude - arriveeLatitude);

    // Convertir l'azimut en degrés
    let azimuthInDegrees = (azimuthInRadians * 180) / Math.PI;

    // Adjust the angle to fit your requirements
    azimuthInDegrees -= 90;

    // Normalize the angle to be within the range [-180, 180]
    if (azimuthInDegrees < -180) {
      azimuthInDegrees += 360;
    } else if (azimuthInDegrees > 180) {
      azimuthInDegrees -= 360;
    }

    // Retourner l'azimut entier
    return Math.round(azimuthInDegrees) * -1;

  }

}
