import { Component, inject } from '@angular/core'
import { GeocodingService } from '../../data-access/http/geocoding.service'
import { GeomapDomainService } from '../../domain/geomap.domain'
import { AddressDto } from '../../model/address-dto'
import { AutocompleteSelectComponent } from '../../../shared/ui/autocomplete-select/autocomplete-select.component'
import { CommonModule } from '@angular/common'
import { NgSelectModule } from '@ng-select/ng-select'
import { RouterLinkWithHref } from '@angular/router'
import { AddressGoogleDto } from '../../model/address-google-dto'

@Component({
  standalone: true,
  imports: [CommonModule, NgSelectModule, AutocompleteSelectComponent, RouterLinkWithHref],
  selector: 'app-control-adresse',
  templateUrl: './control-adresse.component.html',
  styleUrls: ['./control-adresse.component.scss'],
})
export class ControlAdresseComponent {
  private readonly geocodingService = inject(GeocodingService)
  private readonly geomapDomainService = inject(GeomapDomainService)
  searchResults: AddressDto[]
  searchGoogleResults: AddressGoogleDto[]

  onSelectAddress(centerCoordinates: number[]): void {
    if (centerCoordinates) {
      this.geomapDomainService.positionMap(centerCoordinates)
    }
  }

  onSelectGoogleAddress(placeId: string): void {
    if (placeId) {
      console.log("placeId ==> " + placeId)
      this.geomapDomainService.positionGoogleMap(placeId)
    }
  }

  onSearchChange(term: string): void {
    if (term.length >= 3) {
      this.geocodingService.autoCompleteSearch(term).subscribe((searchResults) => (this.searchResults = searchResults))
    }
  }

  onSearchGoogleChange(term: string): void {
    if (term.length >= 4) {
      this.geocodingService.autoCompleteGoogleSearch(term).subscribe((searchResults) => (
          this.searchGoogleResults = searchResults
      ))
    }
  }

}
