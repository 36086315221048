import { AfterViewInit, Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core'
import { AsyncPipe, JsonPipe, NgClass, NgForOf, NgIf } from '@angular/common'
import { RouterLink, RouterLinkWithHref } from '@angular/router'
import Map from 'ol/Map'
import { NgSelectModule } from '@ng-select/ng-select'
import { ControlPanelComponent } from '../control-panel/control-panel.component'
import { GeomapDomainService } from '../../domain/geomap.domain'
import { MapComponent } from '../map/map.component'
import { MeasureDisplayComponent } from '../measure-display/measure-display.component'
import { ControlAdresseComponent } from '../control-adresse/control-adresse.component'
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap'
import { FormParentComponent } from '../form-parent/form-parent.component'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    AsyncPipe,
    RouterLink,
    JsonPipe,
    NgSelectModule,
    NgForOf,
    ControlPanelComponent,
    MapComponent,
    MeasureDisplayComponent,
    ControlAdresseComponent,
    RouterLinkWithHref,
    NgbDropdownModule,
    FormParentComponent,
  ],
})
export class HomeComponent implements AfterViewInit, OnInit {
  constructor() {}

  bNouvelleSimulation: boolean

  onButtonClick() {
    this.bNouvelleSimulation = true
  }

  @ViewChild('tooltip')
  tooltipElement: ElementRef
  private geomapDomainService = inject(GeomapDomainService)
  geoMap: Map = this.geomapDomainService.map

  ngOnInit(): void {
    this.bNouvelleSimulation = false
  }

  ngAfterViewInit(): void {
    this.geomapDomainService.activateTooltips(this.tooltipElement)
  }
}
