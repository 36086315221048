import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { switchMap, takeWhile, timer } from 'rxjs'
import { ProcessResultDto } from './dto/ProcessResultDto'
import { environment } from '../../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private http: HttpClient) {}

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    const path = `${environment.baseUrl}/enedis/upload`
    return this.http.post(path, formData)
  }

  getProcessStatus(processId: string) {
    const path = `${environment.baseUrl}/enedis/upload/status/${processId}`
    return timer(0, 9000).pipe( // Interroge toutes les 9 secondes
      switchMap(() => this.http.get<ProcessResultDto>(path)),
      takeWhile(result => result.status !== 'COMPLETED' && result.status !== 'FAILED', true) // Se termine une fois que le statut est COMPLETED ou FAILED
    );
  }
}
