import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  constructor(private http: HttpClient) {
  }

  downloadCsv(): Observable<Blob> {
    return this.http.get('URL_DU_BACKEND/api/download-csv', { responseType: 'blob' });
  }

}
