import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import { BilanEnergetiqueDto } from './dto/bilan-energetique-dto'
import { environment } from '../../../../environments/environment'


@Injectable({
  providedIn: 'root',
})
export class BilanEnergetiqueService {
  constructor(private http: HttpClient) {
  }

  getPrm(): Observable<BilanEnergetiqueDto[]> {
    const path = `${environment.baseUrl}/enedis/prms`
    return this.http.get<BilanEnergetiqueDto[]>(path)
  }

}
