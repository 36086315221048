<div [formGroup]="capexGeneralisesForm">
  <div class='container'>
    <div class="card">
      <div class="card-body">
        <h2 class="card-title">Choix matériels</h2>
        <div class="row">
          <div class="col-12">
              <label class="form-label" for="typeGammePanneau">Gamme panneau <span class="required">*</span></label>
              <select class="form-select" id="typeGammePanneau" formControlName="typeGammePanneau" tabindex="45"
                      [ngClass]="capexGeneralisesForm.get('typeGammePanneau').invalid && (capexGeneralisesForm.get('typeGammePanneau').dirty || capexGeneralisesForm.get('typeGammePanneau').touched) ? 'is-invalid' : ''">
                <option *ngFor="let typeGammePanneau of typeGammePanneaux" [ngValue]="typeGammePanneau.code"
                        [selected]="capexGeneralisesForm.get('typeGammePanneau').value && typeGammePanneau.code === capexGeneralisesForm.get('typeGammePanneau').value">
                  {{ typeGammePanneau.label }}
                </option>
              </select>
              <div class="invalid-feedback">
                <div *ngIf="capexGeneralisesForm.get('typeGammePanneau').errors?.['required']">Le type de gamme panneau est obligatoire</div>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="form-label" for="typeGammeOnduleur">Gamme onduleur <span class="required">*</span></label>
            <select class="form-select" id="typeGammeOnduleur" formControlName="typeGammeOnduleur" tabindex="46"
                    [ngClass]="capexGeneralisesForm.get('typeGammeOnduleur').invalid && (capexGeneralisesForm.get('typeGammeOnduleur').dirty || capexGeneralisesForm.get('typeGammeOnduleur').touched) ? 'is-invalid' : ''">
              <option *ngFor="let typeGammeOnduleur of typeGammeOnduleurs" [ngValue]="typeGammeOnduleur.code"
                      [selected]="capexGeneralisesForm.get('typeGammeOnduleur').value && typeGammeOnduleur.code === capexGeneralisesForm.get('typeGammeOnduleur').value">
                {{ typeGammeOnduleur.label }}
              </option>
            </select>
            <div class="invalid-feedback">
              <div *ngIf="capexGeneralisesForm.get('typeGammeOnduleur').errors?.['required']">Le type de gamme onduleur est obligatoire</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group form-check" *ngIf="capexGeneralisesForm.get('typeGammeOnduleur').value === 'STRING'">
              <span class="spanCheckbox">ICPE ? (ajoute coffret DC)</span>
              <input class="form-check-input" type="checkbox" formControlName="coffretDc" tabindex="47">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group form-check" *ngIf="capexGeneralisesForm.get('typeGammeOnduleur').value === 'STRING'">
              <span class="spanCheckbox">Paratonnerre présent sur site ?</span>
              <input class="form-check-input" type="checkbox" formControlName="paratonnerre" tabindex="48">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group form-check">
              <span class="spanCheckbox">Proche aérodrome ? (ajoute étude éblouissement)</span>
              <input class="form-check-input" type="checkbox" formControlName="aerodrome" tabindex="49">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class='card'>
      <div class="card-body">
        <h2 class="card-title">Autre</h2>
        <div class="row">
          <div class="col-12">
            <div class="row">
                <label for="prixApportAffaireHtWc" class="form-label">Prix apport affaire</label>
                <div class="col-10">
                  <input type="number" class="form-control" id="prixApportAffaireHtWc" formControlName="prixApportAffaireHtWc" placeholder="Ex : 0" tabindex="50">
                </div>
                <div class="col-2">
                  <span class="spanTextMiddle">€/Wc</span>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div class='card'>
      <div class="card-body">
        <h2 class="card-title">Travaux spécifiques</h2>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <label for="travauxDiversHT" class="form-label">Travaux Divers</label>
                <div class="col-10">
                  <input type="number" class="form-control" id="travauxDiversHT" formControlName="travauxDiversHT" placeholder="Ex : 10000" tabindex="51">
                </div>
                <div class="col-2">
                  <span class="spanTextMiddle">€</span>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

